<template>
  <div>
    <surveys-table
      surveyName="WHOQOL-BREF"
      surveyCode="WHOQOL_BREF"
      :permission="{
        createSurvey: permissions.CREATE_SURVEY_WHOQOL_BREF,
        viewDetail: permissions.SEE_DETAIL_SURVEY_WHOQOL_BREF,
      }"
    ></surveys-table>
  </div>
</template>

<script>
import SurveysTable from "../../../components/Forms/SurveysTable.vue";
import { permissions } from "../../../enum/applicationPermissions";

export default {
  components: {
    SurveysTable,
  },
  data() {
    return {
      permissions: permissions,
    };
  },
};
</script>

<style scoped></style>
